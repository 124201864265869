import * as React from 'react';
import { ISectionProps } from '../Section.types';
import ResponsiveContainer from '../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import { TestIds } from '../constants';
import style from './style/Section.scss';

export type SectionProps = ISectionProps & {
  tagName?: keyof JSX.IntrinsicElements;
  className?: string;
};

const Section: React.FC<SectionProps> = ({
  id,
  skin = 'RectangleArea',
  className = style[skin],
  containerProps,
  children,
  tagName,
}) => {
  const TagName = tagName || ('section' as keyof JSX.IntrinsicElements);
  return (
    <TagName id={id} className={className} data-testid={TestIds.section}>
      <ResponsiveContainer {...containerProps}>{children}</ResponsiveContainer>
    </TagName>
  );
};

export default Section;
